import React from "react"
import classNames from "classnames/bind"
import { motion } from "framer-motion"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const logoPathVariants = {
  in: {
    pathLength: 1,
    fill: "rgba(0,0,0, 1)",
    stroke: "rgba(0,0,0, 1)",
    strokeWidth: 1,
    opacity: 1,
  },
  out: {
    pathLength: 0,
    fill: "rgba(0,0,0, 0)",
    stroke: "rgba(0,0,0, 1)",
    strokeWidth: 1,
    opacity: 0,
  },
}

const logoPathTransition = {
  // default: { duration: 1.2, type: "inertia", velocity: 50 },
  pathLength: { duration: 1.2, type: "tween" },
  fill: { delay: 1.2, duration: 1, ease: [2, 2, 1, 0.1] },
  opacity: { duration: 0 },
  strokeWidth: { delay: 1.2, duration: 0.5, ease: [2, 2, 1, 1] },
}

const Logo = ({ play, onFinish }) => {
  const animationEnd = () => {
    onFinish(true)
  }

  const attrs = {
    transition: logoPathTransition,
    variants: logoPathVariants,
    onAnimationComplete: animationEnd,
  }
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 345 129"
      className={cx("logo")}
      animate="in"
      initial="out"
      onAnimationComplete={animationEnd}
    >
      <g fill="currentColor" fillRule="evenodd">
        <motion.path
          d="M11.2939 40.9275v23.611h4.557c2.139 0 3.814-.31 5.023-.932 1.207-.621 2.123-1.483 2.744-2.589.621-1.103 1-2.416 1.139-3.935.139-1.517.209-3.175.209-4.97 0-1.865-.105-3.486-.313-4.868-.206-1.38-.621-2.553-1.242-3.521-.69-.966-1.623-1.673-2.796-2.123-1.175-.448-2.727-.673-4.661-.673h-4.66zm0-29.619v20.298h4.765c3.521 0 5.885-.88 7.092-2.64 1.209-1.761 1.815-4.332 1.815-7.715 0-3.315-.675-5.8-2.021-7.457-1.346-1.657-3.78-2.486-7.3-2.486h-4.351zM.7309 75.1025V1.3665h15.432c3.381 0 6.264.519 8.647 1.554 2.381 1.036 4.332 2.418 5.851 4.142 1.517 1.727 2.604 3.713 3.262 5.955.655 2.245.982 4.575.982 6.991v2.692c0 2.004-.154 3.694-.465 5.075-.311 1.381-.776 2.589-1.398 3.625-1.175 1.933-2.97 3.59-5.386 4.97 2.487 1.175 4.316 2.9 5.49 5.178 1.172 2.278 1.759 5.385 1.759 9.32v4.143c0 6.49-1.57 11.461-4.711 14.912-3.141 3.454-8.165 5.179-15.068 5.179H.7309z"
          {...attrs}
        />
        <motion.path
          d="M53.6504 75.1023V1.3673h31.481v9.942h-20.918v21.644h18.226v9.941h-18.226v21.644h20.918v10.564z"
          {...attrs}
        />
        <motion.path
          d="M111.333 40.9275v23.611h4.557c2.138 0 3.812-.31 5.022-.932 1.206-.621 2.122-1.483 2.745-2.589.62-1.103 1-2.416 1.139-3.935.138-1.517.206-3.175.206-4.97 0-1.865-.104-3.486-.31-4.868-.209-1.38-.623-2.553-1.244-3.521-.69-.966-1.622-1.673-2.796-2.123-1.174-.448-2.727-.673-4.659-.673h-4.66zm0-29.619v20.298h4.763c3.521 0 5.885-.88 7.094-2.64 1.207-1.761 1.812-4.332 1.812-7.715 0-3.315-.673-5.8-2.02-7.457-1.345-1.657-3.78-2.486-7.299-2.486h-4.35zm-10.563 63.794V1.3665h15.429c3.383 0 6.267.519 8.648 1.554 2.382 1.036 4.332 2.418 5.851 4.142 1.518 1.727 2.606 3.713 3.263 5.955.654 2.245.983 4.575.983 6.991v2.692c0 2.004-.156 3.694-.466 5.075-.311 1.381-.777 2.589-1.398 3.625-1.176 1.933-2.97 3.59-5.385 4.97 2.485 1.175 4.314 2.9 5.49 5.178 1.172 2.278 1.759 5.385 1.759 9.32v4.143c0 6.49-1.572 11.461-4.713 14.912-3.141 3.454-8.165 5.179-15.067 5.179H100.77z"
          {...attrs}
        />
        <motion.path
          d="M164.25 11.3089v22.99h6.008c1.864 0 3.346-.258 4.452-.777 1.104-.517 1.969-1.258 2.589-2.226.552-.966.932-2.156 1.139-3.572.208-1.415.311-3.056.311-4.919 0-1.864-.103-3.503-.311-4.919-.207-1.415-.621-2.642-1.243-3.677-1.311-1.932-3.797-2.9-7.456-2.9h-5.489zm-10.563 63.793V1.3669h16.985c12.427 0 18.64 7.216 18.64 21.645 0 4.349-.673 8.044-2.019 11.08-1.347 3.039-3.712 5.489-7.093 7.353l11.391 33.657h-11.185l-9.838-31.482h-6.318v31.482h-10.563z"
          {...attrs}
        />
        <motion.path
          d="M240.7822 1.367v57.166c0 2.417-.433 4.644-1.295 6.679-.863 2.038-2.089 3.849-3.678 5.436-1.587 1.59-3.416 2.832-5.487 3.73-2.072.895-4.281 1.345-6.629 1.345-2.347 0-4.539-.45-6.575-1.345-2.037-.898-3.85-2.14-5.437-3.73-1.588-1.587-2.832-3.398-3.727-5.436-.9-2.035-1.348-4.262-1.348-6.679V1.367h10.563v56.129c0 2.626.623 4.558 1.865 5.8s2.796 1.864 4.659 1.864c1.864 0 3.418-.622 4.661-1.864 1.243-1.242 1.864-3.174 1.864-5.8V1.367h10.564z"
          {...attrs}
        />
        <motion.path
          d="M293.7002 22.5975h-10.563v-2.382c0-2.416-.569-4.505-1.708-6.265-1.139-1.761-3.055-2.642-5.748-2.642-1.45 0-2.624.277-3.52.83-.898.553-1.623 1.242-2.176 2.07-.553.899-.931 1.916-1.139 3.056-.208 1.139-.31 2.33-.31 3.572 0 1.45.052 2.659.156 3.625.103.968.362 1.83.776 2.589.414.76 1.017 1.416 1.811 1.967.794.554 1.881 1.106 3.263 1.657l8.078 3.211c2.346.898 4.245 1.951 5.696 3.158 1.449 1.209 2.589 2.607 3.417 4.195.759 1.656 1.278 3.539 1.554 5.643.275 2.107.413 4.506.413 7.197 0 3.108-.31 5.991-.932 8.648-.621 2.659-1.622 4.92-3.003 6.783-1.449 1.935-3.35 3.453-5.696 4.557-2.348 1.104-5.213 1.657-8.596 1.657-2.553 0-4.935-.45-7.143-1.346-2.211-.897-4.11-2.139-5.697-3.729-1.589-1.587-2.848-3.434-3.781-5.54-.931-2.105-1.397-4.401-1.397-6.886v-3.936h10.562v3.314c0 1.934.571 3.677 1.708 5.229 1.141 1.554 3.057 2.331 5.748 2.331 1.795 0 3.195-.259 4.195-.777 1-.517 1.778-1.259 2.331-2.226.551-.966.88-2.123.983-3.47.105-1.346.155-2.848.155-4.505 0-1.932-.069-3.521-.207-4.763-.138-1.243-.414-2.242-.828-3.003-.485-.759-1.123-1.381-1.916-1.865-.794-.482-1.847-.999-3.159-1.553l-7.559-3.107c-4.557-1.864-7.611-4.332-9.164-7.404-1.554-3.071-2.333-6.921-2.333-11.547 0-2.76.38-5.385 1.141-7.871.759-2.485 1.899-4.624 3.416-6.42 1.45-1.795 3.297-3.227 5.541-4.298 2.244-1.07 4.92-1.605 8.027-1.605 2.622 0 5.021.483 7.197 1.45s4.055 2.244 5.643 3.831c3.176 3.314 4.764 7.112 4.764 11.392v5.178z"
          {...attrs}
        />
        <motion.path
          d="M344.5469 96.0213c-1.035.552-2.398 1.069-4.09 1.554-1.692.482-3.676.724-5.954.724-2.9 0-5.334-.846-7.301-2.537-1.968-1.693-2.953-3.988-2.953-6.887 0-1.658.242-3.142.727-4.453.481-1.312 1.173-2.521 2.07-3.625.897-1.035 1.916-2.003 3.055-2.9 1.138-.897 2.397-1.83 3.78-2.795h5.281c-2.07 2.002-3.694 4.143-4.867 6.42-1.175 2.279-1.76 4.487-1.76 6.628 0 1.657.5 2.968 1.501 3.936 1 .965 2.192 1.45 3.573 1.45 1.173 0 2.191-.122 3.054-.364s1.675-.568 2.435-.983l1.449 3.832zm0-94.654v57.165c0 2.418-.431 4.645-1.293 6.68-.865 2.038-2.091 3.849-3.678 5.436-1.589 1.59-3.417 2.832-5.487 3.729-2.072.896-4.283 1.346-6.629 1.346-2.348 0-4.539-.45-6.576-1.346-2.037-.897-3.849-2.139-5.436-3.729-1.59-1.587-2.832-3.398-3.729-5.436-.898-2.035-1.346-4.262-1.346-6.68V1.3673h10.563v56.129c0 2.626.622 4.558 1.864 5.8s2.797 1.864 4.66 1.864c1.864 0 3.418-.622 4.66-1.864 1.243-1.242 1.864-3.174 1.864-5.8V1.3673h10.563z"
          {...attrs}
        />
        <motion.path
          d="M96.7686 122.2254l4.205-2.846c1.608 2.722 3.587 4.268 6.864 4.268 3.032 0 5.505-1.546 5.505-3.897 0-2.349-1.608-3.648-5.258-4.947l-2.412-.928c-5.442-2.041-7.359-5.443-7.359-9.153 0-4.639 4.019-8.226 9.648-8.226 3.958 0 7.36 1.793 9.278 5.567l-4.145 2.412c-.99-2.041-2.722-3.217-5.133-3.217-2.35 0-4.391 1.238-4.391 3.403 0 1.916.927 3.401 4.514 4.699l2.538.927c5.255 1.918 8.04 4.702 8.04 9.279 0 4.948-4.207 8.844-10.949 8.844-4.576 0-8.472-2.042-10.945-6.185"
          {...attrs}
        />
        <motion.path d="M125.713 127.915h5.318V84.004h-5.318z" {...attrs} />
        <motion.path
          d="M150.1406 88.8895c0-2.104 1.731-3.772 3.835-3.772 2.166 0 3.834 1.668 3.834 3.772 0 2.165-1.668 3.834-3.834 3.834-2.104 0-3.835-1.669-3.835-3.834m-5.937 20.225h18.864c-.741-5.073-4.699-7.793-9.402-7.732-4.514 0-8.348 3.03-9.462 7.732m-5.751 3.338c0-8.967 6.865-15.956 15.523-15.956 8.659 0 14.968 6.247 15.091 17.256h-25.111c.497 5.504 4.764 9.647 10.392 9.647 3.897 0 7.05-1.731 9.028-3.709l3.403 3.709c-2.659 2.784-7.051 5.011-12.431 5.011-9.154 0-15.895-7.051-15.895-15.958"
          {...attrs}
        />
        <motion.path
          d="M176.2412 96.991h5.319v3.525c2.351-2.968 5.999-4.019 9.587-4.019 6.989 0 11.937 4.886 11.937 12.555v18.863h-5.32V109.36c0-4.514-2.968-7.853-7.793-7.853-4.329 0-7.298 2.412-8.411 6.431v19.977h-5.319V96.991z"
          {...attrs}
        />
        <motion.path
          d="M210.8145 88.8895c0-2.104 1.731-3.772 3.835-3.772 2.166 0 3.834 1.668 3.834 3.772 0 2.165-1.668 3.834-3.834 3.834-2.104 0-3.835-1.669-3.835-3.834zm1.175 39.026h5.319v-30.924h-5.319v30.924z"
          {...attrs}
        />
        <motion.path
          d="M223.7402 122.2254l4.205-2.846c1.608 2.722 3.588 4.268 6.866 4.268 3.03 0 5.503-1.546 5.503-3.897 0-2.349-1.606-3.648-5.256-4.947l-2.412-.928c-5.442-2.041-7.36-5.443-7.36-9.153 0-4.639 4.02-8.226 9.648-8.226 3.959 0 7.36 1.793 9.277 5.567l-4.144 2.412c-.988-2.041-2.721-3.217-5.133-3.217-2.349 0-4.39 1.238-4.39 3.403 0 1.916.927 3.401 4.514 4.699l2.536.927c5.257 1.918 8.04 4.702 8.04 9.279 0 4.948-4.206 8.844-10.947 8.844-4.577 0-8.473-2.042-10.947-6.185"
          {...attrs}
        />
        <motion.path
          d="M300.0732 103.0086c.754.346 1.362.846 2.006 1.373 1.303 1.07 2.78 2.283 5.637 2.283 2.855 0 4.333-1.213 5.636-2.283 1.222-1.002 2.276-1.867 4.477-1.867.505 0 .914-.409.914-.915 0-.505-.409-.915-.914-.915-2.858 0-4.335 1.213-5.639 2.283-1.221 1.002-2.274 1.867-4.474 1.867-2.201 0-3.255-.865-4.476-1.867-.705-.58-1.435-1.178-2.401-1.623-.46-.211-1.004-.01-1.216.449-.21.459-.01 1.004.45 1.215"
          {...attrs}
        />
        <motion.path
          d="M257.1572 102.5145c2.199 0 3.255.866 4.474 1.867 1.305 1.071 2.781 2.283 5.638 2.283 2.856 0 4.333-1.212 5.637-2.283 1.221-1.001 2.274-1.867 4.475-1.867 2.2 0 3.253.866 4.473 1.867 1.304 1.071 2.782 2.283 5.637 2.283 2.855 0 4.334-1.212 5.637-2.283 1.184-.971 2.207-1.81 4.265-1.864.506-.013.906-.433.891408-.939-.012408-.505-.436408-.902-.938408-.891-2.688.07-4.119 1.243-5.379 2.28-1.221 1.002-2.276 1.867-4.476 1.867-2.199 0-3.254-.865-4.475-1.867-1.303-1.07-2.781-2.283-5.635-2.283-2.856 0-4.333 1.213-5.637 2.283-1.221 1.002-2.275 1.867-4.475 1.867-2.201 0-3.255-.865-4.475-1.867-1.303-1.07-2.782-2.283-5.637-2.283-.506 0-.916.41-.916.915 0 .506.41.915.916.915"
          {...attrs}
        />
        <motion.path
          d="M317.8271 109.4627c-2.855 0-4.333 1.213-5.637 2.283-1.22 1.002-2.274 1.867-4.474 1.867-2.201 0-3.255-.865-4.476-1.867-1.304-1.07-2.781-2.283-5.637-2.283-2.855 0-4.333 1.213-5.636 2.283-1.221 1.002-2.276 1.867-4.476 1.867-2.199 0-3.254-.865-4.475-1.867-1.303-1.07-2.781-2.283-5.635-2.283-2.857 0-4.333 1.213-5.638 2.283-1.221 1.002-2.274 1.867-4.474 1.867-2.201 0-3.255-.865-4.475-1.867-1.303-1.07-2.781-2.283-5.637-2.283-.506 0-.916.41-.916.915 0 .506.41.916.916.916 2.199 0 3.255.865 4.474 1.867 1.305 1.071 2.781 2.283 5.638 2.283 2.856 0 4.333-1.212 5.637-2.283 1.221-1.002 2.275-1.867 4.475-1.867 2.2 0 3.253.865 4.473 1.867 1.304 1.071 2.782 2.283 5.637 2.283 2.856 0 4.334-1.212 5.637-2.283 1.22-1.002 2.275-1.867 4.475-1.867 2.202 0 3.255.865 4.476 1.867 1.303 1.071 2.78 2.283 5.637 2.283 2.855 0 4.333-1.212 5.636-2.283 1.222-1.002 2.276-1.867 4.475-1.867.507 0 .916-.41.916-.916 0-.505-.409-.915-.916-.915"
          {...attrs}
        />
        <motion.path
          d="M317.8271 118.241c-2.855 0-4.333 1.213-5.637 2.283-1.22 1.002-2.274 1.867-4.474 1.867-2.201 0-3.255-.865-4.476-1.867-1.304-1.07-2.781-2.283-5.637-2.283-2.855 0-4.333 1.213-5.636 2.283-1.221 1.002-2.276 1.867-4.476 1.867-2.199 0-3.254-.865-4.475-1.867-1.303-1.07-2.781-2.283-5.635-2.283-2.857 0-4.333 1.213-5.638 2.283-1.221 1.002-2.274 1.867-4.474 1.867-2.201 0-3.255-.865-4.475-1.867-1.303-1.07-2.781-2.283-5.637-2.283-.506 0-.916.41-.916.916s.41.916.916.916c2.199 0 3.255.865 4.474 1.867 1.305 1.071 2.781 2.283 5.638 2.283 2.856 0 4.333-1.212 5.637-2.283 1.221-1.002 2.275-1.867 4.475-1.867 2.2 0 3.253.865 4.473 1.867 1.304 1.071 2.782 2.283 5.637 2.283 2.856 0 4.334-1.212 5.637-2.283 1.22-1.002 2.275-1.867 4.475-1.867 2.202 0 3.255.865 4.476 1.867 1.303 1.071 2.78 2.283 5.637 2.283 2.855 0 4.333-1.212 5.636-2.283 1.222-1.002 2.276-1.867 4.475-1.867.507 0 .916-.41.916-.916s-.409-.916-.916-.916"
          {...attrs}
        />
        <motion.path
          d="M44.0967 101.7938c-.211-.459-.756-.66-1.214-.449-.97.445-1.697 1.043-2.402 1.623-1.222 1.002-2.276 1.867-4.475 1.867-2.202 0-3.256-.865-4.475-1.867-1.303-1.07-2.782-2.283-5.638-2.283-.506 0-.916.41-.916.915 0 .506.41.915.916.915 2.199 0 3.253.865 4.475 1.867 1.303 1.07 2.781 2.283 5.638 2.283 2.855 0 4.331-1.213 5.635-2.283.644-.527 1.253-1.027 2.006-1.373.461-.211.66-.756.45-1.215"
          {...attrs}
        />
        <motion.path
          d="M86.5635 102.5145c.506 0 .916-.409.916-.915 0-.505-.41-.915-.916-.915-2.857 0-4.335 1.213-5.639 2.283-1.218 1.002-2.272 1.867-4.474 1.867-2.2 0-3.253-.865-4.475-1.867-1.304-1.07-2.781-2.283-5.636-2.283-2.854 0-4.332 1.213-5.635 2.283-1.222 1.002-2.276 1.867-4.475 1.867-2.2 0-3.256-.865-4.476-1.867-1.26-1.037-2.69-2.21-5.379-2.28-.502-.011-.927.386-.9384085.891-.0145915.506.3854085.926.8914085.939 2.058.054 3.081.893 4.263 1.864 1.304 1.071 2.782 2.283 5.639 2.283 2.854 0 4.332-1.212 5.635-2.283 1.222-1.001 2.275-1.867 4.475-1.867 2.2 0 3.253.866 4.476 1.867 1.303 1.071 2.78 2.283 5.635 2.283 2.857 0 4.335-1.212 5.638-2.283 1.219-1.001 2.276-1.867 4.475-1.867"
          {...attrs}
        />
        <motion.path
          d="M24.9766 110.3777c0 .506.41.916.916.916 2.199 0 3.253.865 4.475 1.867 1.303 1.071 2.781 2.283 5.638 2.283 2.855 0 4.331-1.212 5.635-2.283 1.223-1.002 2.275-1.867 4.476-1.867 2.199 0 3.256.865 4.474 1.867 1.304 1.071 2.782 2.283 5.639 2.283 2.853 0 4.332-1.212 5.635-2.283 1.222-1.002 2.275-1.867 4.475-1.867 2.2 0 3.253.865 4.476 1.867 1.303 1.071 2.78 2.283 5.635 2.283 2.857 0 4.335-1.212 5.638-2.283 1.219-1.002 2.276-1.867 4.475-1.867.506 0 .916-.41.916-.916 0-.505-.41-.915-.916-.915-2.858 0-4.335 1.213-5.639 2.283-1.219 1.002-2.272 1.867-4.474 1.867-2.2 0-3.253-.865-4.473-1.867-1.306-1.07-2.781-2.283-5.638-2.283-2.854 0-4.332 1.213-5.635 2.283-1.222 1.002-2.276 1.867-4.475 1.867-2.2 0-3.256-.865-4.476-1.867-1.303-1.07-2.781-2.283-5.637-2.283-2.855 0-4.332 1.213-5.636 2.283-1.222 1.002-2.275 1.867-4.475 1.867-2.202 0-3.256-.865-4.475-1.867-1.303-1.07-2.782-2.283-5.638-2.283-.506 0-.916.41-.916.915"
          {...attrs}
        />
        <motion.path
          d="M24.9766 119.157c0 .506.41.916.916.916 2.199 0 3.253.865 4.475 1.867 1.303 1.071 2.781 2.283 5.638 2.283 2.855 0 4.331-1.212 5.635-2.283 1.223-1.002 2.275-1.867 4.476-1.867 2.199 0 3.256.865 4.474 1.867 1.304 1.071 2.782 2.283 5.639 2.283 2.853 0 4.332-1.212 5.635-2.283 1.222-1.002 2.275-1.867 4.475-1.867 2.2 0 3.253.865 4.476 1.867 1.303 1.071 2.78 2.283 5.635 2.283 2.857 0 4.335-1.212 5.638-2.283 1.219-1.002 2.276-1.867 4.475-1.867.506 0 .916-.41.916-.916s-.41-.916-.916-.916c-2.858 0-4.335 1.213-5.639 2.283-1.219 1.002-2.272 1.867-4.474 1.867-2.2 0-3.253-.865-4.473-1.867-1.306-1.07-2.781-2.283-5.638-2.283-2.854 0-4.332 1.213-5.635 2.283-1.222 1.002-2.276 1.867-4.475 1.867-2.2 0-3.256-.865-4.476-1.867-1.303-1.07-2.781-2.283-5.637-2.283-2.855 0-4.332 1.213-5.636 2.283-1.222 1.002-2.275 1.867-4.475 1.867-2.202 0-3.256-.865-4.475-1.867-1.303-1.07-2.782-2.283-5.638-2.283-.506 0-.916.41-.916.916"
          {...attrs}
        />
      </g>
    </motion.svg>
  )
}

export default Logo
