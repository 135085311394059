import "css/global.css"
// import "fontsource-sen/latin-ext.css"
import "fontsource-noto-sans"
import "fontsource-noto-sans/700.css"
import "fontsource-noto-serif"
import "fontsource-noto-serif/700.css"
import "fontsource-montserrat/latin-ext.css"
import "fontsource-bebas-neue"
import "es6-promise/auto"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) {
    location.state.referrer = prevLocation ? prevLocation.pathname : null
  }
}

// const transitionDelay = 300

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   if (location.action === "PUSH") {
//     window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
//   } else {
//     const savedPosition = getSavedScrollPosition(location)
//     window.setTimeout(
//       () => window.scrollTo(...(savedPosition || [0, 0])),
//       transitionDelay
//     )
//   }
//   return false
// }
