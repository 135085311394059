import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import Navigation, { NavToggle } from "components/Navigation"
import Logo from "images/logo-main.svg"
import { Link } from "gatsby"

const cx = classNames.bind(styles)

const Header = ({ siteTitle, inContent, className }) => {
  const [navOpen, setNavOpen] = React.useState(false)

  const setNavState = state => {
    setNavOpen(state)
  }

  const handlePageChange = () => {
    setNavOpen(false)
  }

  return (
    <header
      className={cx("header", className, {
        "-transparent": inContent,
        "-navOpen": navOpen,
      })}
    >
      <Link to="/" onClick={handlePageChange}>
        <h1 className={cx("visually-hidden")}>{siteTitle}</h1>
        <Logo className={cx("logo")} />
      </Link>
      <span className={cx("separator")} />

      <Navigation
        inContent={inContent}
        isOpen={navOpen}
        onNavLinkClick={handlePageChange}
      />
      <NavToggle isOpen={navOpen} getNavState={setNavState} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
