import React from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import { ContextProviderComponent } from "./Context"
import classNames from "classnames/bind"
import Header from "components/Header"
import Splash from "components/Splash"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const duration = 0.3

const loaderVariants = {
  initial: {
    opacity: 0,
    // scaleY: 0,
    // originY: 1,
  },
  enter: {
    opacity: 1,
    // originY: 1,
    // scaleY: 1,
  },
  exit: {
    opacity: 0,
    // scaleY: 0,
    // originY: 0,
  },
}

const loaderTransition = {
  default: { duration: duration },
  // originY: { duration: 0 },
}

const variants = {
  initial: {
    opacity: 1,
  },
  enter: {
    opacity: 1,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
    },
  },
  exit: {
    opacity: 1,
    transition: { duration: duration },
  },
}

const Layout = ({ location, children }) => {
  const isHome = location.pathname === "/"
  const [showHeader, setShowHeader] = React.useState(!isHome)
  const [showLoader, setShowLoader] = React.useState(true)
  const [showSplash, setShowSplash] = React.useState(true)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const onStart = () => {
    setShowLoader(true)

    if (isHome) {
      setShowHeader(false)
    }
  }

  const onComplete = () => {
    if (isHome) {
      setShowHeader(true)
    }
    setShowLoader(false)
  }

  const handleOnSplashAnimationEnd = () => {
    setShowSplash(false)
  }

  return (
    <ContextProviderComponent>
      <div className={cx("page")}>
        {showHeader && (
          <Header
            siteTitle={data.site.siteMetadata?.title || `Bebrusų slėnis`}
          />
        )}

        {showSplash && <Splash onFinished={handleOnSplashAnimationEnd} />}

        <AnimatePresence>
          {!showSplash && showLoader && (
            <motion.div
              className={cx("loader")}
              key="loader"
              variants={loaderVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              transition={loaderTransition}
            />
          )}
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter>
          <motion.main
            key={location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            className={cx("main")}
            onAnimationStart={onStart}
            onAnimationComplete={onComplete}
          >
            {children}
          </motion.main>
        </AnimatePresence>
      </div>
    </ContextProviderComponent>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
