module.exports = {
  list: [
    {
      url: "/sklypai",
      title: "Sklypai",
    },
    {
      url: "/apie",
      title: "Apie",
    },
    {
      url: "/vieta",
      title: "Vieta",
    },
    {
      url: "/galerija",
      title: "Galerija",
    },
    {
      url: "/kontaktai",
      title: "Kontaktai",
    },
  ],
}
