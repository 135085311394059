import React from "react"
import { Link } from "gatsby"
import classNames from "classnames/bind"
import { useStaticQuery, graphql } from "gatsby"
import { AnimateSharedLayout, motion } from "framer-motion"
import styles from "./styles.module.css"
import routes from "routes/routes"
import useWindowSize from "hooks/useWindowSize"

const cx = classNames.bind(styles)

const Navigation = ({ className, isOpen, inContent, onNavLinkClick }) => {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      sanityGlobalSettings {
        email
        phone
      }
    }
  `)
  const [currentLocation, setCurrentLocation] = React.useState(null)
  const windowSize = useWindowSize()
  const smallScreen = windowSize.width < 768

  const handleLinkClick = () => {
    onNavLinkClick()
  }
  const isActive = ({ location }) => {
    setCurrentLocation(location.pathname)
  }

  const navVariants = {
    open: {
      opacity: 1,
      transition: {
        duration: 3,
        staggerChildren: 0.5,
        when: "beforeChildren",
      },
    },
    closed: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        staggerDirection: -1,
      },
    },
  }

  // const navItem = {
  //   closed: { opacity: 0 },
  //   open: { opacity: 1 },
  // }

  return (
    <motion.nav
      variants={navVariants}
      className={cx("wrapper", className, {
        "-navOpen": isOpen,
        "-inContent": inContent,
      })}
    >
      <AnimateSharedLayout>
        {routes.list.map((route, index) => {
          const number = ("0" + index).slice(-2)
          const isCurrent =
            currentLocation && currentLocation.startsWith(route.url)

          return (
            <Link
              key={index}
              to={route.url}
              activeClassName="-active"
              className={cx("link")}
              onClick={handleLinkClick}
              getProps={isActive}
              partiallyActive={true}
            >
              <span className={cx("number")}>{number}</span>
              <span className={cx("title")}>{route.title}</span>
              {!smallScreen && isCurrent && (
                <motion.div className={cx("line")} layoutId="underline" />
              )}
            </Link>
          )
        })}
      </AnimateSharedLayout>
      {smallScreen && (
        <div className={cx("contacts")}>
          <a
            href={`mailto:${data.sanityGlobalSettings?.email}`}
            className={cx("contacts-item")}
          >
            {data.sanityGlobalSettings?.email}
          </a>
          <a
            href={`tel:${data.sanityGlobalSettings?.phone}`}
            className={cx("contacts-item")}
          >
            {data.sanityGlobalSettings?.phone}
          </a>
        </div>
      )}
    </motion.nav>
  )
}

export default Navigation
