// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apie-js": () => import("./../../../src/pages/apie.js" /* webpackChunkName: "component---src-pages-apie-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-sklypai-js": () => import("./../../../src/pages/sklypai.js" /* webpackChunkName: "component---src-pages-sklypai-js" */),
  "component---src-pages-vieta-js": () => import("./../../../src/pages/vieta.js" /* webpackChunkName: "component---src-pages-vieta-js" */),
  "component---src-templates-landplot-js": () => import("./../../../src/templates/landplot.js" /* webpackChunkName: "component---src-templates-landplot-js" */)
}

