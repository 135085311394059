import React from "react"
import classNames from "classnames/bind"
import { motion } from "framer-motion"
import styles from "./styles.module.css"
import Logo from "./Logo"

const cx = classNames.bind(styles)

const variants = {
  in: {
    y: "0%",
  },
  out: {
    y: "-100%",
  },
}

const variantsCover = {
  in: {
    y: "0%",
  },
  out: {
    y: "100%",
  },
}

const Splash = ({ onFinished }) => {
  const [play, setPlay] = React.useState(false)

  const attrs = {
    initial: "in",
    animate: play && "out",
    exit: "out",
    transition: { type: "tween", duration: 0.5, ease: "easeInOut" },
  }

  const handleOnFinish = () => {
    setPlay(true)
  }

  return (
    <motion.div
      className={cx("splash")}
      variants={variants}
      onAnimationComplete={() => onFinished()}
      {...attrs}
    >
      <motion.div
        className={cx("splash-cover")}
        variants={variantsCover}
        {...attrs}
      >
        <Logo play={!play} onFinish={handleOnFinish} />
      </motion.div>
    </motion.div>
  )
}

export default Splash
