import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"

const cx = classNames.bind(styles)

const NavToggle = ({ getNavState, isOpen }) => {
  const [open, setOpen] = React.useState(isOpen)

  React.useEffect(() => {
    getNavState(open)
  }, [open])

  const handleOnClick = () => {
    setOpen(!open)
  }

  return (
    <button className={cx("toggle")} onClick={handleOnClick}>
      {!isOpen ? `meniu` : `uždaryti`}
    </button>
  )
}

NavToggle.propTypes = {}

export default NavToggle
